import React, { useState, useEffect } from "react";
import { Empty, Select } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams } from "../../../utils/Utils";
import { Badge, 
  Card, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const ExpensesTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, expenses:[]});
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    expense_search: '',
    supplier_search: '',
    date_range: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    status: 'all',
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onDateChange = (date, type) => {
    let params = filters;

    if(!moment(date).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    if(type === 'from') {
      params.dateRangeStart = date;  
      setFromDate(date);  
    } else {
      params.dateRangeEnd = date;
      setToDate(date);  
    }

    if(moment(params.dateRangeStart).isValid() && moment(params.dateRangeEnd).isValid()) {
      params.date_range = `${moment(params.dateRangeStart).format("YYYY-MM-DD")},${moment(params.dateRangeEnd).format("YYYY-MM-DD")}`;
      setFilters({...params});      
    }
  }

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.expenses.map(item => item.expense_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let expense_search = filters.expense_search.length > 0 ? `&es=${filters.expense_search}` : ``;
    let supplier_search = filters.supplier_search.length > 0 ? `&sp=${filters.supplier_search}` : ``;
    let date_range = filters.date_range !== null ? `&r=${filters.date_range}` : ``;
    let status = filters.status !== null ? `&s=${filters.status}` : ``;
    
    if( (expense_search !== '' || supplier_search !== '' ||
    date_range !== '' ||
    filters.status !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${expense_search}${supplier_search}${date_range}${status}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getExpenses(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      expense_search: '',
      supplier_search: '',
      date_range: null,
      dateRangeStart: null,
      dateRangeEnd: null,
      status: 'all'
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFromDate(null);
    setToDate(null);
    setFilters({ ...params });
    getExpenses(params);
  }

  const filterExpenses = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getExpenses(filters);
  }

  const getExpenses = (filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + "/expenses", {
      params: {
        business_id: businessID,
        page: filters.page,
        expense: filters.expense_search,
        supplier: filters.supplier_search,
        date_from: !filters.dateRangeStart ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !filters.dateRangeEnd ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, expenses:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, expenses:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteExpensesByIDs = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/expenses/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        expenses: JSON.stringify(checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_expense_ids;
      let failedIDs = responseInfo.data.failed_expense_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getExpenses(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getExpenses(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `expenses` : `expense`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `expenses` : `expense`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `expenses` : `expense`}. The selected ${failedIDs.length > 1 ? `expenses were` : `expense was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteExpensesByFilter = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/expenses/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        expense: filters.expense_search,
        supplier: filters.supplier_search,
        date_from: !filters.dateRangeStart ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !filters.dateRangeEnd ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        status: filters.status
      }
    })
    .then((response) => {
      getExpenses(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const handleSingleDelete = (expenseID) => {
    setCheckedBoxes([expenseID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');       
    }, 500);
  }

  const downloadExpenses = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/expenses/download", {
      params: {
        business_id: businessID,
        expense: filters.expense_search,
        supplier: filters.supplier_search,
        date_from: !filters.dateRangeStart ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !filters.dateRangeEnd ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No expense found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let expense_search = queryParams.hasOwnProperty('es') ? queryParams.es : '';
    let supplier_search = queryParams.hasOwnProperty('sp') ? queryParams.sp : '';
    let date_range = queryParams.hasOwnProperty('r') ? queryParams.r : null;
    let status = queryParams.hasOwnProperty('s') ? queryParams.s : 'all';
    let dateRangeStart = null;
    let dateRangeEnd = null;

    if(date_range !== null){
      let rangeSplit = date_range.split(',');
      dateRangeStart = new Date(rangeSplit[0]);
      dateRangeEnd = new Date(rangeSplit[1]);
      setFromDate(dateRangeStart);
      setToDate(dateRangeEnd);
    }

    let params = filters;
    params.page = page;
    params.expense_search = expense_search;
    params.supplier_search = supplier_search;
    params.date_range = date_range;
    params.dateRangeStart = dateRangeStart;
    params.dateRangeEnd = dateRangeEnd;
    params.status = status;

    setFilters(params)
    getExpenses(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          {
            tableData.expenses.length > 0 &&
            <div className="row g-gs mb-5">
              <div className="col-md-12">
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-success">Amount Paid</div>
                        <div className="amount"><small className="text-muted fw-light">{currency}</small> {tableData.meta.amount_paid}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-danger">Amount Owed</div>
                        <div className="amount"><small className="text-muted fw-light">{currency}</small> {tableData.meta.amount_owed}</div>
                      </div>
                    </div>
                  </div>
                </Card>   
              </div>
            </div>
          }
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Records` : `Record`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Expenses Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="expense_search">
                                    Expense Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="expense_search" className="form-control form-control-lg" id="expense_search" defaultValue={filters.expense_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="supplier_search">
                                    Supplier Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="supplier_search" className="form-control form-control-lg" id="supplier_search" defaultValue={filters.supplier_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="status">
                                    Status
                                  </label>
                                  <div className="form-control-wrap">
                                  <Select size="large"                          
                                      placeholder="Select status"
                                      defaultValue={filters.status}
                                      style={{ width: "100%" }} 
                                      name="status"
                                      onChange={(value) => onSelectChange(value, "status")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "paid", label: "Paid Expenses"},
                                        {value: "owed", label: "Owed Expenses"},
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">From Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={fromDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'from');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">To Date</label>                          
                                      <div className="form-control-wrap">
                                        <DatePicker
                                          selected={toDate}
                                          dateFormat="dd-MMM-yyyy"
                                          onChange={date => {
                                            onDateChange(date, 'to');
                                          }}
                                          
                                          showMonthDropdown
                                          showYearDropdown
                                          isClearable
                                          className="form-control form-control-lg date-picker"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-lighter justify-content-center">
                              <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterExpenses();} } size="lg">
                                Apply Filter
                              </Button>

                              {
                                hasFilters &&
                                <Button className="ms-3 text-muted" color="light" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="lg">
                                  Reset Filter
                                </Button>
                              }
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getExpenses(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadExpenses} title="Download Expenses">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Expenses"} descriptionText={"Please wait while your expenses are being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Expenses Ready"} descriptionText={"Expenses CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="card-inner p-0">
                {
                  tableData.expenses.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No expense found</h5><span>Record your business spending and purchases.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Record an Expense</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Category</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Expense</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Total</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Status</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Date</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('cancel')}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Expenses</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Expenses` : `Delete Expense`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} expenses. This action can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected expenses` : `this selected expense`}. This action can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteExpensesByFilter : deleteExpensesByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No expense has been selected. Select an expense and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `expenses are` : `expense is`} being deleted`} /> }
                        
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.expenses.map((data, index) => {
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                {
                                  data.has_supplier === 'no' &&
                                  <div className="custom-control custom-control-sm custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={checkAll || checkedBoxes.includes(data.expense_id)}
                                      onChange={() => handleCheckboxChange(data.expense_id)}
                                      className="custom-control-input table-checkbox"
                                      id={`item${data.expense_id}`} 
                                    />
                                    <label className="custom-control-label" htmlFor={`item${data.expense_id}`} />
                                  </div>
                                }
                              </DataTableRow>
                              <DataTableRow>
                                <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}/details/${data.expense_id}`}>
                                  <div className="user-card">
                                    <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                    <div className="user-info">
                                      <div className={`tb-lead`}>
                                        {data.account}{" "}
                                      </div>
                                      { 
                                        data.has_supplier === 'yes' &&
                                        <small className="text-muted" title="supplier"><em className="icon ni ni-user-alt me-1"></em>{data.supplier_name}</small>
                                      }

                                      <span className="d-md-none">{data.expense_name}</span>
                                      <div className={`price d-md-none`}>
                                        <span className="text-muted"><small>Total: {currency} </small> <b>{data.total_cost}</b></span>
                                        <span className="ms-2">
                                          {
                                            data.status === 'paid' ?
                                            <Badge className="badge-dot" color="success">Paid</Badge>
                                            : 
                                            <Badge className="badge-dot" color="danger">Owed</Badge>
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.expense_name}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small>{currency} </small> {data.total_cost}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                {
                                  data.status === 'paid' ?
                                  <div className="tb-tnx-status text-end">
                                  <Badge className="badge-dot" color="success">Paid</Badge>
                                  </div> 
                                  : 
                                  <div className="tb-tnx-status text-end">
                                    <Badge className="badge-dot" color="danger">Owed</Badge>
                                  </div>
                                }
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{moment(data.expense_date).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}/details/${data.expense_id}`}>                                            
                                          <Icon name="eye"></Icon>
                                          <span>View</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/expenses/b/${businessID}/edit/${data.expense_id}`}>
                                          <Icon name="edit-alt"></Icon>
                                          <span>Edit</span>
                                        </Link>
                                      </li>
                                      {
                                        data.has_supplier === 'no' &&
                                        <>
                                          <li className="divider"></li>
                                          <li>
                                            <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.expense_id)}>
                                              <Icon name="trash"></Icon>
                                              <span>Delete</span>
                                            </DropdownItem>
                                          </li>
                                        </>
                                      }
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }

      { 
        activeModal === "successDeleteModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Deleted`} descriptionText={`Expense(s) deleted successfully.`} 
        />
      }
      
      {
        activeModal === "deleteErrorModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={"Error"} descriptionText={errorMessage}                       
        />
      }
    </Block>
  );
};
