import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Spinner, Alert } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { CardLoader } from "../utils/Loaders";
import moment from "moment";
import axios from 'axios';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { OrdersTable } from "./components/tables/OrdersTable";
import { StorefrontOrdersLineChart } from "./components/AnalyticsCharts";
import { AlertModal, LoadingModal, SuccessModal, InfoModal } from "./components/AlertModals";

const Storefront = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [loadingSalesStats, setLoadingSalesStats] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [storefrontOnboarding, setStorefrontOnboarding] = useState({
    published: "no",
    storefront_url: null,
    completed_storefront_onboarding: "no", 
    completed_business_verification: "no", 
    completed_storefront_setup: "no", 
    completed_fulfillment_setup: "no", 
    completed_storefront_appearance: "no", 
  });
  const [copy, setCopy] = useState(false);
  const [canPublish, setCanPublish] = useState(false);
  const [dashboardStats, setDashboardStats] = useState({});
  const [ordersDuration, setOrdersDuration] = useState("week");
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onCopyClick = () => {
    setCopy(true);
    setTimeout(() => setCopy(false), 2000);
  };

  const publishStorefront = () => {
    setErrorMessage("");
    setRequesting(true);

    setLoadingMessage(`Please wait while your storefront is being published.`);
    toggleModal('loadingModal');
    
    axios.post(baseURLs.API_URL + `/storefront/publish/${businessID}`, null, getAxiosHeaders())
    .then((response) => {

      if(storefrontOnboarding.completed_storefront_onboarding === "no") {
        setStorefrontOnboarding({
          ...storefrontOnboarding,
          completed_storefront_onboarding: "yes",
          published: "yes"
        })
        getStorefrontStats(businessID);
        
      } else {
        setStorefrontOnboarding({
          ...storefrontOnboarding,
          published: "yes"
        })
      }
      setSuccessTitle("Storefront Published");
      setSuccessMessage(`Your Storefront is online and accessible to your customers.`);
      toggleModal('successModal');

      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal(null)
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const unpublishStorefront = () => {
    setErrorMessage("");
    setRequesting(true);

    setLoadingMessage(`Please wait while your storefront is being unpublished.`);
    toggleModal('loadingModal');
    
    axios.post(baseURLs.API_URL + `/storefront/unpublish/${businessID}`, null, getAxiosHeaders())
    .then((response) => {
      
      setStorefrontOnboarding({
        ...storefrontOnboarding,
        published: "no"
      })
      setSuccessTitle("Storefront Unpublished");
      setSuccessMessage(`Your Storefront is offline and not accessible to your customers.`);
      toggleModal('successModal');

      setRequesting(false);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal(null)
        setRequesting(false);
        window.scrollTo({top: 0, behavior: 'smooth'});
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }
  
  const getStorefrontOnboardingStatus = (businessID) => {
    axios.get(baseURLs.API_URL + `/storefront/onboarding`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: businessID
      },
    })
    .then((response) => {
      let responseInfo = response.data;
      let onboardingStatus = responseInfo.data;
      if(onboardingStatus.completed_storefront_setup === "yes" || 
        onboardingStatus.completed_fulfillment_setup === "yes" || 
        onboardingStatus.completed_storefront_appearance === "yes") {
          setCanPublish(true);
      }

      setStorefrontOnboarding(onboardingStatus);
      
      if(onboardingStatus.completed_storefront_onboarding === "yes") {
        getStorefrontStats(businessID);
      }

      setLoading(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getStorefrontStats = (businessID) => {
    setLoadingSalesStats(true);
    axios.get(baseURLs.API_URL + "/statistics/storefront", {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;

      setDashboardStats(responseInfo.data);
      setLoadingSalesStats(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoadingSalesStats(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getStorefrontOnboardingStatus(businessID);

  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Storefront" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        {
          storefrontOnboarding.completed_storefront_onboarding === "no" ?
          <>
            {/* storefront onboarding */}
            <BlockHead size="sm">
                  <div className="nk-block-between-md justify-content-center text-center">
                <BlockHeadContent>
                  <div className="nk-block-head-sub"><span>Welcome to Storefront, {businessInfo.name}</span></div>
                  <BlockTitle page tag="h2" className="mb-2">
                    Ready to Sell Online?
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <p>Complete the steps below to bring your business online and reach more customers.</p>
                  </BlockDes>
                </BlockHeadContent>
              </div>
            </BlockHead>

            <Block className="wide-sm m-auto">
              <div className="card card-bordered mt-4">
                <Link to={`${process.env.PUBLIC_URL}/business-verification/b/${businessID}`}>
                  <div className={`fm-head border-bottom ${storefrontOnboarding.completed_business_verification === "yes" ? `bg-lighter`: ``}`}>
                    {
                      storefrontOnboarding.completed_business_verification === "yes" ?
                      <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                      :
                      <div className="fm-count"><Icon name="shield-star fs-22px"></Icon></div>
                    }
                    <div className="fm-title">
                      <h5 className="title fw-normal">Verify Your Business</h5>
                      <p className="nk-block-des">Verify your business identity with a valid national ID card and registration documents (if applicable). We prioritize security and authenticity to protect both you and your customers.</p>
                    </div>
                  </div>
                </Link>

                <Link to={`${process.env.PUBLIC_URL}/storefront-settings/b/${businessID}`}>
                  <div className={`fm-head border-bottom ${storefrontOnboarding.completed_storefront_setup === "yes" ? `bg-lighter`: ``}`}>
                    {
                      storefrontOnboarding.completed_storefront_setup === "yes" ?
                      <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                      :
                      <div className="fm-count"><Icon name="browser fs-22px"></Icon></div>
                    }
                    <div className="fm-title">
                      <h5 className="title fw-normal">Set Up Your Storefront</h5>
                      <p className="nk-block-des">Create your online space by adding your URL, writing your terms and conditions, describing your business, and adding your social media links.</p>
                    </div>
                  </div>
                </Link>

                <Link to={`${process.env.PUBLIC_URL}/storefront-fulfillment-settings/b/${businessID}`}>
                  <div className={`fm-head border-bottom ${storefrontOnboarding.completed_fulfillment_setup === "yes" ? `bg-lighter`: ``}`}>
                    {
                      storefrontOnboarding.completed_fulfillment_setup === "yes" ?
                      <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                      :
                      <div className="fm-count"><Icon name="truck fs-22px"></Icon></div>
                    }
                    <div className="fm-title">
                      <h5 className="title fw-normal">Choose Your Fulfillment Options</h5>
                      <p className="nk-block-des">Choose between delivery, pickup, or both, and specify how you'll charge for delivery.</p>
                    </div>
                  </div>
                </Link>

                <Link to={`${process.env.PUBLIC_URL}/storefront-appearance-settings/b/${businessID}`}>
                  <div className={`fm-head border-bottom ${storefrontOnboarding.completed_storefront_appearance === "yes" ? `bg-lighter`: ``}`}>
                    {
                      storefrontOnboarding.completed_storefront_appearance === "yes" ?
                      <div className="fm-count bg-success text-white"><Icon name="check-thick fs-22px"></Icon></div>
                      :
                      <div className="fm-count"><Icon name="template fs-22px"></Icon></div>
                    }
                    <div className="fm-title">
                      <h5 className="title fw-normal">Personalize Your Storefront's Look</h5>
                      <p className="nk-block-des">Make your storefront unique by adding your logos, banners, and share your story with an engaging "About Us" section.</p>
                    </div>
                  </div>
                </Link>
                <div className="fm-footer">
                  <div className="text-center">
                    <Button disabled={!canPublish} className="m-1" color="primary" size="lg" onClick={() => toggleModal("publishModal")}>
                      Publish Storefront
                    </Button>
                  </div>
                </div>
              </div>
            </Block>
          </>
          :
          <>
            {/* storefront dashboard */}
            <BlockHead size="sm">
              <div className="nk-block-between">
                <BlockHeadContent>
                  <BlockDes className="text-soft">
                    <p>{businessInfo.name}</p>
                  </BlockDes>
                  <BlockTitle page tag="h3">
                    Storefront
                  </BlockTitle>
                  
                </BlockHeadContent>
                <BlockHeadContent>
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <Button
                      className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="more-v"></Icon>
                    </Button>
                    <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                      <ul className="nk-block-tools g-3">
                        <li>
                          {
                            storefrontOnboarding.published === "yes" ?
                            <Button color="danger" onClick={() => toggleModal("unpublishModal")}>
                              <Icon name="na"></Icon>
                              <span>Unpublish Storefront</span>
                            </Button>
                            :
                            <Button color="primary" onClick={() => toggleModal("publishModal")}>
                              <Icon name="browser"></Icon>
                              <span>Publish Storefront</span>
                            </Button>
                          }
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle className="dropdown-toggle btn btn-outline-light" color="none">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/storefront/b/${businessID}/abandoned-carts`}>
                                    <Icon name="cart"></Icon>
                                    <span>Abandoned Cart</span>
                                  </Link>
                                </li>
                                <li className="divider"></li> 
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/storefront-settings/b/${businessID}`}>
                                    <Icon name="setting"></Icon>
                                    <span>Storefront Settings</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/storefront-fulfillment-settings/b/${businessID}`}>
                                    <Icon name="truck"></Icon>
                                    <span>Storefront Fulfillment Settings</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`${process.env.PUBLIC_URL}/storefront-appearance-settings/b/${businessID}`}>
                                    <Icon name="template"></Icon>
                                    <span>Storefront Appearance Settings</span>
                                  </Link>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                  </div>
                </BlockHeadContent>
              </div>
            </BlockHead>

            <Block>
              {errorMessage && (
                  <div className="mb-3">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {errorMessage}{" "}
                    </Alert>
                  </div>
              )}
            </Block>

            <Block>
              {
                loadingSalesStats ?
                <Row className="g-gs">
                  <Col lg="7"><CardLoader /></Col>
                  <Col lg="5"><CardLoader /></Col>
                </Row>
                :
                <Row className="g-gs">
                  {
                    storefrontOnboarding.published === "no" &&
                    <Col lg="12">
                      <div className="card-bordered card mb-2">
                        <div className="card-inner">
                          <div className="nk-help">
                            <div className="nk-help-img m-0">
                              <lord-icon
                                src="https://cdn.lordicon.com/cosvjkbu.json"
                                trigger="loop"
                                delay="1000"
                                colors="primary:#66a1ee,secondary:#646e78,tertiary:#3a3347"
                                style={{width:"120px",height:"120px"}}>
                              </lord-icon>
                            </div>
                            <div className="nk-help-text">
                              <h5> Publish Storefront</h5>
                              <p className="text-soft">Ready to go live? Click the 'Publish' button to make your storefront visible to customers.</p>
                            </div>
                            <div className="nk-help-action">
                            <button className="btn btn-outline-primary" onClick={() => toggleModal("publishModal")}>
                              Publish Storefront
                            </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  }
                  <Col lg="7">
                    <PreviewAltCard>
                      <div className="card-title-group pb-3 g-2">
                        <div className="card-title">
                          <h6 className="title">Online Orders ({moment().format('Y')})</h6>
                          <p>Breakdown of your online orders.</p>
                        </div>
                        <div className="card-tools shrink-0 ">
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-sm btn-white btn-dim btn-outline-light">
                              <Icon name="calender-date"></Icon>
                              <span className="text-capitalize">
                                This {ordersDuration}
                              </span>
                              <Icon className="dd-indc" name="chevron-right"></Icon>
                            </DropdownToggle>
                            <DropdownMenu>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    href="#this-week"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setOrdersDuration("week");

                                    }}
                                  >
                                    This Week
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    href="#this-month"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setOrdersDuration("month");
                                    }}
                                  >
                                    This Month
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    href="#this-year"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setOrdersDuration("year");
                                    }}
                                  >
                                    This Year
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                      <div className="sales-stats">
                        {
                          ordersDuration === "week" &&
                          <div className="nk-sale-data-group flex-md-nowrap g-4">
                            <div className="nk-sale-data">
                              <div className="title fw-bold">Total Amount</div>
                              <div className="amount"><small className="fs-16px text-muted">{businessInfo.currency} </small> {dashboardStats?.orders_stats.charts.meta.total_week_orders_amount}</div>
                              {/* <div className="change up">
                                <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                              </div> */}
                            </div>
                            
                            <div className="nk-sale-data">
                              <div className="title fw-bold">No. of Orders</div>
                              <div className="amount">{dashboardStats?.orders_stats.charts.meta.total_week_orders_count}</div>
                              {/* <div className="change up">
                                <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                              </div> */}
                            </div>
                          </div>
                        }

                        {
                          ordersDuration === "month" &&
                          <div className="nk-sale-data-group flex-md-nowrap g-4">
                            <div className="nk-sale-data">
                              <div className="title fw-bold">Total Amount</div>
                              <div className="amount"><small className="fs-16px text-muted">{businessInfo.currency} </small> {dashboardStats?.orders_stats.charts.meta.total_month_orders_amount}</div>
                              {/* <div className="change up">
                                <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                              </div> */}
                            </div>
                            
                            <div className="nk-sale-data">
                              <div className="title fw-bold">No. of Orders</div>
                              <div className="amount">{dashboardStats?.orders_stats.charts.meta.total_month_orders_count}</div>
                              {/* <div className="change up">
                                <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                              </div> */}
                            </div>
                          </div>
                        }

                        {
                          ordersDuration === "year" &&
                          <div className="nk-sale-data-group flex-md-nowrap g-4">
                            <div className="nk-sale-data">
                              <div className="title fw-bold">Total Amount</div>
                              <div className="amount"><small className="fs-16px text-muted">{businessInfo.currency} </small> {dashboardStats?.orders_stats.charts.meta.total_year_orders_amount}</div>
                              {/* <div className="change up">
                                <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                              </div> */}
                            </div>
                            
                            <div className="nk-sale-data">
                              <div className="title fw-bold">No. of Orders</div>
                              <div className="amount">{dashboardStats?.orders_stats.charts.meta.total_year_orders_count}</div>
                              {/* <div className="change up">
                                <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
                              </div> */}
                            </div>
                          </div>
                        }
                        <div className="mt-5">
                          <StorefrontOrdersLineChart duration={ordersDuration} chatData={dashboardStats?.orders_stats.charts.chart_info} />
                        </div>
                      </div>
                    </PreviewAltCard>
                  </Col>
                  <Col lg="5">
                    <PreviewAltCard>
                      <div className="nk-wg-action d-block">
                        <div className="nk-wg-action-content">
                          <em className="icon ni ni-link-alt"></em>
                          <div className="title fw-bold">Storefront Link</div>                          
                        </div>
                        <div className={`form-control-wrap ${copy ? "clipboard-success" : ""} mt-2`}>
                          <div className="form-clip">
                            <CopyToClipboard
                              text={storefrontOnboarding.storefront_url}
                              className="clipboard-init"
                              onCopy={onCopyClick}
                            >
                              <div>
                                <Icon name="copy" className="clipboard-icon fs-6" style={{lineHeight: "inherit"}}></Icon>
                                <span className="clipboard-text">{copy ? "Copied" : "Copy"}</span>
                              </div>
                            </CopyToClipboard>
                          </div>
                          <input
                            type="text"
                            className="form-control copy-text w-100"
                            id="txt_record"
                            defaultValue={storefrontOnboarding.storefront_url}
                            value={storefrontOnboarding.storefront_url}/>
                        </div>
                      </div>
                    </PreviewAltCard>
                    <PreviewAltCard>
                      <Link to={`${process.env.PUBLIC_URL}/orders/b/${businessID}?p=1&st=all&so=storefront`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-cart"></em>
                            <div className="title fw-bold">Total Orders</div>
                            <h4 className="amount fw-normal">
                              {dashboardStats?.orders_stats.total_orders.total_count}
                              <small className="text-muted fs-16px"> / {businessInfo.currency} {dashboardStats?.orders_stats.total_orders.total_amount} </small>
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/orders/b/${businessID}?p=1&st=all&so=storefront`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-alert-circle text-danger"></em>
                            <div className="title fw-bold">Pending Orders</div>
                            <h4 className="amount fw-normal">
                              {dashboardStats?.orders_stats.pending_orders.total_count}
                              <small className="text-muted fs-16px"> / {businessInfo.currency} {dashboardStats?.orders_stats.pending_orders.total_amount} </small>
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/orders/b/${businessID}?p=1&st=delivered&so=storefront`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-check-c"></em>
                            <div className="title fw-bold">Completed Orders</div>
                            <h4 className="amount fw-normal">
                              {dashboardStats?.orders_stats.completed_orders.total_count}
                              <small className="text-muted fs-16px"> / {businessInfo.currency} {dashboardStats?.orders_stats.completed_orders.total_amount} </small>
                            </h4>
                          </div>
                        </div>
                      </Link>
                      <hr className="mt-4 mb-4"/>
                      <Link to={`${process.env.PUBLIC_URL}/storefront/b/${businessID}/abandoned-carts`} className="text-base">
                        <div className="nk-wg-action">
                          <div className="nk-wg-action-content">
                            <em className="icon ni ni-caution text-warning"></em>
                            <div className="title fw-bold">Abandoned Carts</div>
                            <h4 className="amount fw-normal">
                              {dashboardStats?.orders_stats.abandoned_cart.total_count}
                              <small className="text-muted fs-16px"> / {businessInfo.currency} {dashboardStats?.orders_stats.abandoned_cart.total_amount} </small>
                            </h4>
                          </div>
                        </div>
                      </Link>
                    </PreviewAltCard>
                  </Col>
                </Row>
              }

            </Block>

            <OrdersTable history={history} businessID={businessID} currency={businessInfo.currency} storefrontView={true} />
            

          </>
        }
        
        {/* loading modal */}
        { 
          activeModal === "loadingModal" &&
          <LoadingModal showModal={true} headerText={`Please wait...`} descriptionText={loadingMessage} />
        }

        {/* unpublish modal */}
        { 
          activeModal === "unpublishModal" &&
          <AlertModal 
            index="alert-key"
            showModal={true} 
            toggleModal={() => toggleModal('unpublishModal')}
            headerText={`Unpublish Storefront?`}
            descriptionText={`Are you sure you want to unpublish storefront? This will make your storefront inaccessible to your customers.`}
            leftButtonText={`Unpublish`}
            leftButtonOnClick={unpublishStorefront} />
        }

        {
          activeModal === "publishModal" &&
          <InfoModal 
            index="accept"
            showModal={true} 
            toggleModal={() => toggleModal('publishModal')}
            headerText={`Publish Storefront?`}
            descriptionText={`Are you sure you want to publish your storefront? This will make your storefront accessible to your customers.`}
            leftButtonText={`Publish`}
            leftButtonOnClick={publishStorefront}
            rightButtonText={`Close`}
            rightButtonOnClick={() => toggleModal('publishModal')} />
        }

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={successTitle} descriptionText={successMessage} 
          />
        }

        {
          activeModal === "alertModal" &&
          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
            headerText={"Error"} descriptionText={errorMessage}                       
          />
        }
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default Storefront;