export const baseURLs = {
  KUS_URL:  process.env.REACT_APP_MODE === 'production' ? 
    "https://keepup.store" : process.env.REACT_APP_MODE === 'uat' ? 
    "https://uat-website.keepup.store" : "http://localhost:7000",
  CLIENT_APP_URL:  process.env.REACT_APP_MODE === 'production' ? 
    "https://app.keepup.store" : process.env.REACT_APP_MODE === 'uat' ? 
    "https://uat-app.keepup.store" : "http://localhost:3000",
  API_URL:  process.env.REACT_APP_MODE === 'production' ? 
    "https://api.keepup.store/v2.0" : process.env.REACT_APP_MODE === 'uat' ? 
    "https://uat-api.keepup.store/v2.0" : "http://localhost:5050/v2.0",
  LIVE_CHAT: "https://tawk.to/chat/5e76db0e8d24fc226589341c/default",
  GOOGLE_CLIENT_ID: "789834819910-2thi430p2pk1jnibb64uctknqtbapnfe.apps.googleusercontent.com",
  CUSTOMER_IMPORT_TEMPLATE_LINK: "https://business-exports.s3.eu-central-1.amazonaws.com/import_customer_template_v2.csv",
  INVENTORY_IMPORT_TEMPLATE_LINK: "https://business-exports.s3.eu-central-1.amazonaws.com/bulk_product_upload_v3.csv",
  RAW_MATERIAL_IMPORT_TEMPLATE_LINK: "https://business-exports.s3.eu-central-1.amazonaws.com/import_raw_materials_template_v2.csv",
  SERVICES_IMPORT_TEMPLATE_LINK: "https://business-exports.s3.eu-central-1.amazonaws.com/import_services_template_v2.csv",
  LOCATIONS_IMPORT_TEMPLATE_LINK: "https://business-exports.s3.eu-central-1.amazonaws.com/import_locations_template_v2.csv",
  STOREFRONT_GENERIC_TERMS_AND_CONDITIONS: "https://business-exports.s3.eu-central-1.amazonaws.com/Storefront_Generic_Terms_and_Conditions.pdf",
  API_DOCUMENTATION_LINK: "https://docs.keepup.store/docs/api/introduction",
  TUTORIAL_LINK: "https://docs.keepup.store/docs/tutorials/overview",
  CNAME_DOMAIN: "storefront.keepup.store",
}

export const fcmVAPID = "BPuETUScGPsTYBo-IPyF313feRXthLnxIZ3XKyltGA65-ZIGdr-BOy_ODKmiLX6qOYGZpLS1NdTmxB3lP0JEms8";

export const subscriptionPackages = {
  PRICING: {
    GHS: {
      currency: 'GHS',
      start_up: {
        id: 1,
        cost: {
          monthly: 40,
          six_months: 220,
          yearly: 440,
        },
        package_name: 'Start-up'
      },
      growth: {
        id: 2,
        cost: {
          monthly: 80,
          six_months: 440,
          yearly: 880,
        },
        package_name: 'Growth'
      },
      expansion: {
        id: 3,
        cost: {
          monthly: 150,
          six_months: 830,
          yearly: 1650,
        },
        package_name: 'Expansion'
      },
    },
    NGN: {
      currency: 'NGN',
      start_up: {
        id: 1,
        cost: {
          monthly: 5000,
          six_months: 28000,
          yearly: 55000,
        },
        package_name: 'Start-up'
      },
      growth: {
        id: 2,
        cost: {
          monthly: 10000,
          six_months: 55000,
          yearly: 110000,
        },
        package_name: 'Growth'
      },
      expansion: {
        id: 3,
        cost: {
          monthly: 24000,
          six_months: 132000,
          yearly: 264000,
        },
        package_name: 'Expansion'
      },
    },
    KES: {
      currency: 'KES',
      start_up: {
        id: 1,
        cost: {
          monthly: 400,
          six_months: 2200,
          yearly: 4400,
        },
        package_name: 'Start-up'
      },
      growth: {
        id: 2,
        cost: {
          monthly: 800,
          six_months: 4400,
          yearly: 8800,
        },
        package_name: 'Growth'
      },
      expansion: {
        id: 3,
        cost: {
          monthly: 2000,
          six_months: 11000,
          yearly: 22000,
        },
        package_name: 'Expansion'
      },
    },
    ZAR: {
      currency: 'ZAR',
      start_up: {
        id: 1,
        cost: {
          monthly: 60,
          six_months: 330,
          yearly: 660,
        },
        package_name: 'Start-up'
      },
      growth: {
        id: 2,
        cost: {
          monthly: 120,
          six_months: 660,
          yearly: 1320,
        },
        package_name: 'Growth'
      },
      expansion: {
        id: 3,
        cost: {
          monthly: 260,
          six_months: 1430,
          yearly: 2860,
        },
        package_name: 'Expansion'
      },
    },
    USD: {
      currency: 'USD',
      start_up: {
        id: 1,
        cost: {
          monthly: 3,
          six_months: 17,
          yearly: 33,
        },
        package_name: 'Start-up'
      },
      growth: {
        id: 2,
        cost: {
          monthly: 6,
          six_months: 33,
          yearly: 66,
        },
        package_name: 'Growth'
      },
      expansion: {
        id: 3,
        cost: {
          monthly: 14,
          six_months: 77,
          yearly: 154,
        },
        package_name: 'Expansion'
      },
    }
  },
  PLANS: {
    start_up: {
      receipt: 50,
      inventory: 20,
      warehouse: 1,
      team: 2,
      promo: 10
    },
    growth : {
      receipt: 500,
      inventory: 500,
      warehouse: 3,
      team: 5,
      promo: 150
    },
    expansion: {
      receipt: 1000000,
      inventory: 1000000,
      warehouse: 1000000,
      team: 1000000,
      promo: 1000
    },
  }
}

export const subscriptionCurrencies = [
  "GHS", "NGN", "KES", "ZAR", "USD"
];

export const paystackCurrencies = [
  "GHS", "NGN", "KES", "ZAR", "USD"
];

export const flutterwaveCurrencies = [
  "GBP", "CAD", "XAF", "CLP", "COP", "EGP", "EUR", "GHS", "GNF", "KES", "MWK", "MAD", "NGN", "RWF", "SLL", "STD", "ZAR", "TZS", 
  "UGX", "USD", "XOF", "ZMW"
];

export const stripeCurrencies = [
  "USD", "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", "AWG", "AZN", "BAM", "BBD", "BDT", "BGN", "BIF", "BMD", "BND", 
  "BOB", "BRL", "BSD", "BWP", "BYN", "BZD", "CAD", "CDF", "CHF", "CLP", "CNY", "COP", "CRC", "CVE", "CZK", "DJF", "DKK", "DOP", 
  "DZD", "EGP", "ETB", "EUR", "FJD", "FKP", "GBP", "GEL", "GIP", "GMD", "GNF", "GTQ", "GYD", "HKD", "HNL", "HTG", "HUF", "IDR", 
  "ILS", "INR", "ISK", "JMD", "JPY", "KES", "KGS", "KHR", "KMF", "KRW", "KYD", "KZT", "LAK", "LBP", "LKR", "LRD", "LSL", "MAD", 
  "MDL", "MGA", "MKD", "MMK", "MNT", "MOP", "MUR", "MVR", "MWK", "MXN", "MYR", "MZN", "NAD", "NGN", "NIO", "NOK", "NPR", "NZD", 
  "PAB", "PEN", "PGK", "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR", "SBD", "SCR", "SEK", "SGD", "SHP", 
  "SLE", "SOS", "SRD", "STD", "SZL", "THB", "TJS", "TOP", "TRY", "TTD", "TWD", "TZS", "UAH", "UGX", "UYU", "UZS", "VND", "VUV", 
  "WST", "XAF", "XCD", "XOF", "XPF", "YER", "ZAR", "ZMW"
];

export const payoutAccounts = [
  {
      "id": 76,
      "name": "Absa Bank Ghana Ltd",
      "slug": "absa-bank-ghana-ltd",
      "code": "030100",
      "active": false,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank",
  },
  {
      "id": 31,
      "name": "Access Bank",
      "slug": "access-bank-ghana",
      "code": "280100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 32,
      "name": "ADB Bank Limited",
      "slug": "adb-bank-limited",
      "code": "080100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 752,
      "name": "Affinity Ghana Savings and Loans",
      "slug": "affinity-bank-gh",
      "code": "300341",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 29,
      "name": "AirtelTigo",
      "slug": "atl-mobile-money",
      "code": "ATL",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "mobile_money"
  },
  {
      "id": 182,
      "name": "ARB Apex Bank",
      "slug": "arb-apex-bank",
      "code": "070101",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 33,
      "name": "Bank of Africa Ghana",
      "slug": "bank-of-africa-ghana",
      "code": "210100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 77,
      "name": "Bank of Ghana",
      "slug": "bank-of-ghana",
      "code": "010100",
      "active": false,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 696,
      "name": "Best Point Savings & Loans",
      "slug": "best-point-savings-n-loans-gh",
      "code": "300335",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 37,
      "name": "CAL Bank Limited",
      "slug": "cal-bank-limited",
      "code": "140100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 78,
      "name": "Consolidated Bank Ghana Limited",
      "slug": "consolidated-bank-ghana-limited",
      "code": "340100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 38,
      "name": "Ecobank Ghana Limited",
      "slug": "ecobank-ghana-limited",
      "code": "130100",
      "active": false,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 40,
      "name": "FBNBank Ghana Limited",
      "slug": "fbnbank-ghana-limited",
      "code": "200100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 41,
      "name": "Fidelity Bank Ghana Limited",
      "slug": "fidelity-bank-ghana-limited",
      "code": "240100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 42,
      "name": "First Atlantic Bank Limited",
      "slug": "first-atlantic-bank-limited",
      "code": "170100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 43,
      "name": "First National Bank Ghana Limited",
      "slug": "first-national-bank-ghana-limited",
      "code": "330100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 44,
      "name": "GCB Bank Limited",
      "slug": "gcb-bank-limited",
      "code": "040100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 46,
      "name": "Guaranty Trust Bank (Ghana) Limited",
      "slug": "guaranty-trust-bank-(ghana)-limited",
      "code": "230100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 28,
      "name": "MTN",
      "slug": "mtn-mobile-money",
      "code": "MTN",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "mobile_money"
  },
  {
      "id": 49,
      "name": "National Investment Bank Limited",
      "slug": "national-investment-bank-limited",
      "code": "050100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 61,
      "name": "OmniBSCI Bank",
      "slug": "omnibank-ghana-limited",
      "code": "360100",
      "active": false,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 50,
      "name": "Prudential Bank Limited",
      "slug": "prudential-bank-limited",
      "code": "180100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 47,
      "name": "Republic Bank (GH) Limited",
      "slug": "republic-bank-of-ghana-limited",
      "code": "110100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 80,
      "name": "Services Integrity Savings and Loans",
      "slug": "services-integrity-savings-and-loans",
      "code": "300361",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 51,
      "name": "Société Générale Ghana Limited",
      "slug": "société-générale-ghana-limited",
      "code": "090100",
      "active": false,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 52,
      "name": "Stanbic Bank Ghana Limited",
      "slug": "stanbic-bank-ghana-limited",
      "code": "190100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 53,
      "name": "Standard Chartered Bank Ghana Limited",
      "slug": "standard-chartered-bank-ghana-limited",
      "code": "020100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 56,
      "name": "United Bank for Africa Ghana Limited",
      "slug": "united-bank-for-africa-ghana-limited",
      "code": "060100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 57,
      "name": "Universal Merchant Bank Ghana Limited",
      "slug": "universal-merchant-bank-ghana-limited",
      "code": "100100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  },
  {
      "id": 66,
      "name": "Vodafone",
      "slug": "vod-mobile-money",
      "code": "VOD",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "mobile_money"
  },
  {
      "id": 58,
      "name": "Zenith Bank Ghana",
      "slug": "zenith-bank-ghana",
      "code": "120100",
      "active": true,
      "country": "Ghana",
      "currency": "GHS",
      "type": "bank"
  }
]

export const units = [
  {"singular": "Kilogram", "plural": "Kilograms", "abbreviation": "kg", "category": "Weight"},
  {"singular": "Gram", "plural": "Grams", "abbreviation": "g", "category": "Weight"},
  {"singular": "Metric Ton", "plural": "Metric Tons", "abbreviation": "tonne", "category": "Weight"},
  {"singular": "Pound", "plural": "Pounds", "abbreviation": "lb", "category": "Weight"},
  {"singular": "Ounce", "plural": "Ounces", "abbreviation": "oz", "category": "Weight"},
  {"singular": "Liter", "plural": "Liters", "abbreviation": "L", "category": "Volume"},
  {"singular": "Milliliter", "plural": "Milliliters", "abbreviation": "ml", "category": "Volume"},
  {"singular": "Cubic Meter", "plural": "Cubic Meters", "abbreviation": "m³", "category": "Volume"},
  {"singular": "Cubic Foot", "plural": "Cubic Feet", "abbreviation": "ft³", "category": "Volume"},
  {"singular": "Gallon", "plural": "Gallons", "abbreviation": "gal", "category": "Volume"},
  {"singular": "Quart", "plural": "Quarts", "abbreviation": "qt", "category": "Volume"},
  {"singular": "Meter", "plural": "Meters", "abbreviation": "m", "category": "Length"},
  {"singular": "Centimeter", "plural": "Centimeters", "abbreviation": "cm", "category": "Length"},
  {"singular": "Inch", "plural": "Inches", "abbreviation": "in", "category": "Length"},
  {"singular": "Foot", "plural": "Feet", "abbreviation": "ft", "category": "Length"},
  {"singular": "Yard", "plural": "Yards", "abbreviation": "yd", "category": "Length"},
  {"singular": "Each", "plural": "Each", "abbreviation": "ea", "category": "Count/Quantity"},
  {"singular": "Dozen", "plural": "Dozens", "abbreviation": "doz", "category": "Count/Quantity"},
  {"singular": "Box", "plural": "Boxes", "abbreviation": "box", "category": "Count/Quantity"},
  {"singular": "Case", "plural": "Cases", "abbreviation": "case", "category": "Count/Quantity"},
  {"singular": "Pallet", "plural": "Pallets", "abbreviation": "pallet", "category": "Count/Quantity"},
  {"singular": "Square Meter", "plural": "Square Meters", "abbreviation": "m²", "category": "Area"},
  {"singular": "Square Foot", "plural": "Square Feet", "abbreviation": "ft²", "category": "Area"},
  {"singular": "Pieces", "plural": "Pieces", "abbreviation": "pcs", "category": "Other"},
  {"singular": "Roll", "plural": "Rolls", "abbreviation": "roll", "category": "Other"},
  {"singular": "Bundle", "plural": "Bundles", "abbreviation": "bundle", "category": "Other"},
  {"singular": "Bale", "plural": "Bales", "abbreviation": "bale", "category": "Other"},
  {"singular": "Bag", "plural": "Bags", "abbreviation": "bg", "category": "Other"},
  {"singular": "Sack", "plural": "Sacks", "abbreviation": "sck", "category": "Other"},
  {"singular": "Ream", "plural": "Reams", "abbreviation": "ream", "category": "Other"}
]
